import * as React from 'react';

import { Box, Card, CardContent, Grid } from '@mui/material';
import StaticAppBar from '../../components/nav-section/horizontal/StaticAppBar';

const DashboardLayout: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <main>
      <StaticAppBar />

      <Grid
        container
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        marginY={10}
      >
        {children}
      </Grid>
    </main>
  );
};

export default DashboardLayout;
