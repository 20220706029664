import * as React from "react";
import { navigate } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress, Typography } from "@mui/material";
import IndexLayout from "./IndexLayout";

const PlatformLayout: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {

    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return (
            <IndexLayout>
                <Typography variant="h2">Loading...</Typography>
                <br />
                <CircularProgress /> 
            </IndexLayout>
        )
    } else if(isAuthenticated) {
        return <>{children}</>;
    } else {
        return (
           <>
             {navigate("/401/")}
           </>
        )
    }
}
export default PlatformLayout;