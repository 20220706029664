import * as React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { navigate, PageProps } from 'gatsby';
import ThemeProvider from './src/theme';
import HubspotScript from './src/components/HubspotScript';
import PlatformLayout from './src/layouts/login/PlatformLayout';

/**
 * For full docs on gatsvby-browser, see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

/**
 * Helper function to navigate to the correct page after login.
 * @param appState the state of the app before the callback, used to reference where to redirect after authentication is succesful
 */
const onRedirectCallback = (appState: AppState | undefined) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/dashboard/', { replace: true });
};

/**
 * Wraps the root element, useful for context provider used in the entire application. We use it for the Auth0Provider, to wrap all our pages with authentication
 * @param element the children elements of this page (in other words, React elements)
 * @returns wrapped page
 */
export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN!}
      clientId={process.env.GATSBY_AUTH0_CLIENTID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {element}
    </Auth0Provider>
  );
};

/**
 * Used to wrap every page with useful layouts and other UI wrappers.
 * @param element  the children elements of this page (in other words, React elements)
 * @returns wrapped page
 */
export const wrapPageElement = ({
  element,
  props,
}: {
  element: React.ReactNode;
  props: PageProps;
}) => {
  if (!(props.path === ('/' || '/404/' || '/401/'))) {
    return (
      <PlatformLayout>
        <ThemeProvider {...props}>
          <HubspotScript />
          {element}
        </ThemeProvider>
      </PlatformLayout>
    );
  } else {
    return (
      <ThemeProvider {...props}>
        <HubspotScript />
        {element}
      </ThemeProvider>
    );
  }
};
