import * as React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
} from '@mui/material';
import {
  Adb as AdbIcon,
  Home as HomeIcon,
  AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';

const StaticAppBar: React.FC = (): JSX.Element => {
  return (
    <AppBar position="static" color="transparent">
      <Container
        maxWidth="xl"
        sx={{
          minHeight: 64,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: 'row',
        }}
      >
        <img
          src="https://global-uploads.webflow.com/5ee34869dd28cd4237e2a5f2/6489e1a1ecc6f9aaa67ebb8b_symbol.svg"
          alt="Winc logo"
          style={{ height: '2rem' }}
        />
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mx: 2,
            display: { xs: 'none', md: 'flex' },
            letterSpacing: '.05rem',
            fontWeight: 700,
            color: 'inherit',
            textDecoration: 'none',
            flexGrow: 1,
          }}
        >
          Winc Student Portal
        </Typography>
      </Container>
    </AppBar>
  );
};

export default StaticAppBar;
